import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import 'normalize.css';
import favicon from '../../../static/favicon.ico';
import Header from '../Header';
import LegalPopup from '../LegalPopup';
import HomeFooter from '../HomeFooter';

import s from './Layout.module.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  currentPage: PropTypes.string,
  logoClass: PropTypes.string,
  layoutStyle: PropTypes.shape({
    background: PropTypes.string,
  }).isRequired,
  seo: PropTypes.shape({
    lang: PropTypes.string.isRequired,
    openGraph: PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      pathname: PropTypes.string,
    }).isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  footerDescription: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.array,
    }).isRequired,
  }).isRequired,
};

const { GATSBY_BASE_URL } = process.env;

const excludedPages = [
  '/events/data-strategy-genai-business-growth',
  '/events/aws-lasummit-happy-hour',
  '/events/2024-dodgers-vip-networking',
  '/events/2024-dodgers-vip-networking-media-industry',
  '/events/2024-dodgers-vip-networking-marketing-industry',
  '/events/2024-dodgers-vip-networking-it-industry',
];

const defaultProps = {
  currentPage: '',
  logoClass: '',
};

const Layout = ({
  children,
  currentPage,
  logoClass,
  layoutStyle,
  seo,
  title,
  footerDescription,
}) => {
  return (
    <div className={s.root}>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta property="og:title" content={seo?.openGraph?.title} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${GATSBY_BASE_URL}${seo?.openGraph?.pathname}`} />
        <meta property="og:image" content={seo?.openGraph?.image} />
        <meta property="og:description" content={seo?.openGraph?.description} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo?.openGraph?.title} />
        <meta name="twitter:description" content={seo?.openGraph?.description} />
        <meta name="twitter:image" content={seo?.openGraph?.image} />
        <title>{title}</title>
        <meta name="description" content={seo?.openGraph?.description} />
        {!excludedPages.includes(currentPage) ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : (
          <meta name="robots" content="index" />
        )}
        <link rel="icon" href={favicon} />
        <link rel="canonical" href={seo?.openGraph?.pathname} />
      </Helmet>
      {!excludedPages.includes(currentPage) && (
        <Header currentPage={currentPage} logoClass={logoClass} layoutStyle={layoutStyle} />
      )}
      {children}
      <LegalPopup />
      <div className={s.homeFooter}>
        {!excludedPages.includes(currentPage) && (
          <HomeFooter currentPage={currentPage} footerDescription={footerDescription} />
        )}
      </div>
    </div>
  );
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;
export default Layout;
